import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../utils/state";

import LightThemeLogo from "../assets/imgs/light-theme-logo.png";
import DarkThemeLogo from "../assets/imgs/dark-theme-logo.png";

const FooterWrapper = styled.div<{ theme: "light" | "dark" }>`
  box-shadow: 6px -5px 10px 0px rgba(0, 0, 0, 0.14);
  background-color: ${(props: any) =>
    props.theme === "light" ? "white" : "#042360"};
  color: ${(props: any) => (props.theme === "light" ? "#042360" : "white")};
  font-size: 12px;
  font-weight: bold;

  .wrapper-row {
    margin-bottom: -20px;
    border-bottom: ${(props: any) =>
      props.theme === "light" ? "20px solid white" : "20px solid #042360"};
  }

  .copyright-text-holder {
    color: ${(props: any) => (props.theme === "light" ? "#042360" : "white")};
    margin-top: -50px;
    margin-left: 50px;
  }

  .copyright-text {
    color: ${(props: any) => (props.theme === "light" ? "#042360" : "white")};
  }

  ul li {
    padding: 5px 0;
    color: ${(props: any) => (props.theme === "light" ? "#042360" : "white")};
  }

  ul li a {
    color: ${(props: any) => (props.theme === "light" ? "#042360" : "white")};
  }

  .brand-logo {
      width: ${(props: any) => (props.theme === "light" ? "unset" : "75%")};
      margin-bottom: ${(props: any) =>
        props.theme === "light" ? "unset" : "15%"};
    }

  @media screen and (max-width: 650px) {
    .social-links-col {
      margin-top: -15vh;
    }
  }

  @media only screen and (min-width: 481px) and (min-width: 601px) and (max-width: 1024px) {
    .brand-logo {
      width: 100%;
    }
    .copyright-text-holder {
      margin-top: unset;
    }
  }
`;

/**

 * - create the footer
 * - save user theme choice in local storage
 * - add react helmet to navbar - name page title according to it
 */

const Footer = () => {
  const { theme } = useContext(AppContext);

  /**
   * create the page according to how it is for large screen
   * create a footer befitting the small screen
   */

  return (
    <FooterWrapper theme={theme} className="container-fluid">
      <div className="row wrapper-row">
        <div className="col s12 m4 l4 hide-on-small-only">
          {theme === "light" ? (
            <img
              className="brand-logo"
              src={LightThemeLogo}
              alt="payledger-logo"
            />
          ) : (
            <img
              className="brand-logo"
              src={DarkThemeLogo}
              alt="payledger-logo"
            />
          )}
          <div className="copyright-text-holder">
            <i className="fa fa-copyright" aria-hidden="true"></i>{" "}
            <span className="copyright-text">
              Copyright {new Date().getFullYear()}
            </span>
          </div>
        </div>
        <div className="col s12 m8 l8">
          <div className="row">
            <div className="col s6 m3 l3">
              <h5>Products</h5>
              <ul>
                <li>
                  <a href="#!">Invoice</a>
                </li>
                <li>
                  <a href="#!">Transfer Funds</a>
                </li>
                <li>
                  <a href="#!">Virtual Account</a>
                </li>
              </ul>
            </div>
            <div className="col s6 m3 l3">
              <h5>Company</h5>
              <ul>
                <li>
                  <a href="#!">About Us</a>
                </li>
              </ul>
            </div>
            <div className="col s6 m3 l3">
              <h5>Legal</h5>
              <ul>
                <li>
                  <a href="#!">Privacy</a>
                </li>
                <li>
                  <a href="#!">Terms of Use</a>
                </li>
                <li>
                  <a href="#!">FAQ</a>
                </li>
              </ul>
            </div>
            <div className="col s6 m3 l3 social-links-col">
              <h5>Social</h5>
              <ul>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://X.com/payledger"
                  >
                    Twitter (X)
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://LinkedIn.com/company/payledger"
                  >
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/payledger"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/payledger"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col s12 m4 l4 hide-on-med-and-up">
          {theme === "light" ? (
            <img
              className="brand-logo"
              src={LightThemeLogo}
              alt="payledger-logo"
            />
          ) : (
            <img
              className="brand-logo"
              src={DarkThemeLogo}
              alt="payledger-logo"
            />
          )}
          <div className="copyright-text-holder">
            <i className="fa fa-copyright" aria-hidden="true"></i>{" "}
            <span className="copyright-text">
              Copyright {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
